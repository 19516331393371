import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import { Link } from "gatsby";
import contentfulClient from "../../utils/contentful-helper";
import Layout from "../../components/layout";
import "../../styles/pages/properties.scss";

const allCategories = ["rent", "lease", "sale"];

export default function ServiceProviders() {
  const [data, setData] = useState({});
  const [category, setCategory] = useState("all");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(_get(data, "skip", 0));
  const { items, total, skip, limit } = data;

  const handleCategory = (cat) => {
    setCategory(cat);
    setPage(0);
  };

  const getData = () => {
    setLoading(true);
    return contentfulClient
      .getEntries({
        content_type: "property",
        "fields.availability": category !== "all" ? category : undefined,
        skip: page * 10,
        limit: 10,
      })
      .then((d) => {
        setData({ ...d });
      })
      .catch((err) => {
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, [category, page]);

  return (
    <Layout title="Properties for Rental, Lease and Sale">
      <h1 className="page-title">Properties({total})</h1>
      <div className="category-list">
        <ul>
          <li
            onClick={() => handleCategory("all")}
            className={category === "all" ? "active" : ""}
          >
            all
          </li>
          {allCategories.map((c) => {
            return (
              <li
                onClick={() => handleCategory(c)}
                className={category === c ? "active" : ""}
                key={c}
              >
                {c}
              </li>
            );
          })}
        </ul>
      </div>
      {loading ? (
        <div className="loading">
          <p>Loading, please wait!</p>
        </div>
      ) : total === 0 ? (
        <div className="empty">
          <h3>Sorry!</h3>
          <p>No property for {category} at the moment.</p>
        </div>
      ) : (
        <div className="property-list">
          <ul>
            {items?.map((d) => {
              const thumb = `${_get(
                d,
                "fields.gallery[0].fields.file.url",
                ""
              )}`;
              const bedrooms = `${_get(d, "fields.bedrooms", 0)}`;
              const availability = `${_get(d, "fields.availability", "")}`;
              const propertyType = `${_get(d, "fields.propertyType", "")}`;
              const price = `${_get(d, "fields.price", "")}`;
              const area = `${_get(d, "fields.area", "")}`;
              const furnished = `${_get(d, "fields.furnishing", "")}`;
              const street = `${_get(d, "fields.street", "")}`;
              const buildingName = `${_get(d, "fields.buildingName", "")}`;
              const location =
                street.length > 1
                  ? street
                  : buildingName.length > 1
                  ? buildingName
                  : "Semmancheri";
              const title =
                propertyType === "plot"
                  ? `${area} sq.ft ${propertyType} for ${availability} in ${location}`
                  : `${bedrooms}BHK ${propertyType} for ${availability} in ${location}`;
              const priceInterval =
                availability === "lease"
                  ? "/ year"
                  : availability === "rent"
                  ? "/ month"
                  : "";
              return (
                <li className="property-list-item">
                  <Link to={`/properties/details?id=${_get(d, "sys.id")}`}>
                    <div className="property-thumb">
                      <img src={thumb} alt="property-photo" />
                    </div>
                    <div className="property-info">
                      <h3>{title}</h3>
                      <p className="property-type-label">
                        <span>{propertyType}</span>
                      </p>
                      <p>
                        {area} sq.ft {furnished}
                      </p>
                      <p className="property-price">
                        <strong>
                          ₹{price} <span>{priceInterval}</span>
                        </strong>
                      </p>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="property-actions">
            <button onClick={() => setPage(page - 1)} disabled={page <= 0}>
              Prev
            </button>
            <button
              onClick={() => setPage(page + 1)}
              disabled={total < limit + skip}
            >
              Next
            </button>
          </div>
        </div>
      )}
      <div className="adding-new-data">
        <p>
          If you want your property to be listed here, kindly email it to{" "}
          <a href="mailto:nlrrwa2018@gmail.com">nlrrwa2018@gmail.com</a> to we
          will get it updated here. Please include all the details like photos,
          pricing, location and other information about your property in the
          email.
        </p>
      </div>
    </Layout>
  );
}
